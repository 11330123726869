<template>
  <div class="mappingCenterAll">
    <div class="mappingCenterFirst">
      <div class="mappingCenterFirst1">
        <h1>虚影-数据隐蔽防御系统</h1>
        <p>
          借助高级浏览器特性，实现了对浏览器的所有网络请求进行加密的创新性方案。加密后的请求参数将被安全地发送至反向代理，这一层代理将负责解密请求并将其传递到真实的后端服务。在保障通信的安全性的同时，能够有效应对潜在的网络安全威胁。这个全面而创新的解决方案不仅提升了数据传输的安全性，还在技术上展现了高度的灵活性和可扩展性。
        </p>
      </div>
    </div>
    <div class="box">
      <ul class="tabs clearfix">
        <li class="tab-link" @click="scrollToElement('proGs')">产品概述</li>
        <li class="tab-link" @click="scrollToElement('proJg')">产品架构</li>
        <li class="tab-link" @click="scrollToElement('proXg')">产品效果</li>
        <li class="tab-link" @click="scrollToElement('proCj')">应用场景</li>
        <li class="tab-link" @click="scrollToElement('proYs')">产品优势</li>
      </ul>
    </div>
    <div class="mappingCenterSecond">
      <h1 id="proGs">产品概述</h1>
      <div class="mappingCenterSecond1">
        <div class="mappingCenterSecond1Left">
          <img src="../../../../assets/img/proctCenter/6.webp" alt="" />
        </div>
        <div class="mappingCenterSecond1Right">
          <p>
            在信息技术迅猛发展的今天,网络安全已成为全球关注的重要议题。随着网络空间成为新的信息战场,从个⼏隐私保护到国家安全,网络安全的挑战日益复杂和多样。尤其在云计算和大数据的时代背景下,网络安全不仅是技术问题,更是社会和经济安全的重要组成部分。
          </p>
          <p>
            虚影-数据隐蔽防御系统
            是一款领先的安全解决方案，通过借助高级浏览器特性，实现了对浏览器所有网络请求进行创新性的加密。该系统在保障通信安全性的同时，通过反向代理解密机制，有效应对潜在的网络安全威胁。全面而创新的解决方案不仅提升了数据传输的安全性，还在技术上展现了高度的灵活性和可扩展性。
          </p>
        </div>
      </div>
    </div>
    <div class="mappingCenterThird">
      <h1 id="proJg">产品架构</h1>
      <div class="mappingCenterThird1">
        <img src="../../../../assets/img/proctCenter/20.jpg" alt="" />
        <img src="../../../../assets/img/proctCenter/21.jpg" alt="" />
      </div>
    </div>
    <div class="mappingCenterFourth">
      <h1 id="proXg">产品效果</h1>
      <div class="mappingCenterFourth1">
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/2.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-隐藏API</h2>
            <p>通过抓包工具抓包所有API请求路径和参数皆被加密,还原成本巨大</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/3.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-反抓包分析</h2>
            <p>流量层全加密,流量层密文传输。</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/4.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-反脚本访问</h2>
            <p>非浏览器正常请求访问皆被阻拦。</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/5.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-爬虫</h2>
            <p>浏览器上无法正常保存静态资源,通过脚本访问皆被阻拦。</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/6.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-反数据泄露</h2>
            <p>返回包全加密,无明文数据。</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/7.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-反0day利用</h2>
            <p>市面上所有0day攻击方式全部失效,天然防御恶意请求。</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/8.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-反调试</h2>
            <p>浏览器层面多重调试检测,极大增加攻击者破解成本</p>
          </div>
        </div>
        <div class="mappingCenterFourth1-1">
          <img src="../../../../assets/img/proctCenter/13.png" alt="" />
          <div class="mappingCenterFourth1-1-1">
            <h2>-隐藏API</h2>
            <p>
              浏览器环境检测,判断是否为正常用户,脚本或恶意用户无法正常访问系统。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mappingCenterFiveth">
      <h1 id="proCj">应用场景</h1>
      <div class="mappingCenterFiveth1">
        <div class="mappingCenterFiveth1-1">
          <img src="../../../../assets/img/proctCenter/14.png" alt="" />
          <div class="mappingCenterFiveth1-1-1">
            <h2>业务保护</h2>
            <p>
              某公司下站点存在发送验证码和上传文件功能,在某日凌晨被恶意攻击者大量使用自动化脚本访问验证码接口和上传超大文件。最终导致公司SMS服务
              和OSS资源耗尽,业务停滞,造成大额损失。
            </p>
          </div>
        </div>
        <div class="mappingCenterFiveth1-1">
          <img src="../../../../assets/img/proctCenter/15.png" alt="" />
          <div class="mappingCenterFiveth1-1-1">
            <h2>数据防护</h2>
            <p>
              某站点平时大量请求来自于网络爬虫和业务爬虫,经常导致服务资源不拒从而影响正常业务。同时大量的业务爬虫严重侵害了知识产权。
            </p>
          </div>
        </div>
        <div class="mappingCenterFiveth1-1">
          <img src="../../../../assets/img/proctCenter/16.png" alt="" />
          <div class="mappingCenterFiveth1-1-1">
            <h2>站点保护</h2>
            <p>
              某公司管理大量站点,由于内部原因没办法对所有站点进行全方面的安全融试,导致某站点的接口存在信息泄露漏洞,被攻击者使用脚本批量访问,导致大量客户信息泄露并在外网售卖,被工信部门责令罚款整改。
            </p>
          </div>
        </div>
        <div class="mappingCenterFiveth1-1">
          <img src="../../../../assets/img/proctCenter/17.png" alt="" />
          <div class="mappingCenterFiveth1-1-1">
            <h2>0day防护</h2>
            <p>
              护网行动前期,某知名邮件系统疑似传出0day漏洞,但防守方并不知晓漏洞的真实性和是
              否存在补丁,又因为邮件系统
              为业务核心系统不可关停。导致在护网期间被攻击者通过邮件系统0day漏洞成功进入内网,最终早早出局。
            </p>
          </div>
        </div>
        <div class="mappingCenterFiveth1-1">
          <img src="../../../../assets/img/proctCenter/15.png" alt="" />
          <div class="mappingCenterFiveth1-1-1">
            <h2>刷单保护</h2>
            <p>
              某金融公司的举办某线上活动时未严格设置风控措施,导致恶意攻击者批量使用大量账号访问接口进行"刷单"。造成大量经济损失。
            </p>
          </div>
        </div>
        <div class="mappingCenterFiveth1-1">
          <img src="../../../../assets/img/proctCenter/17.png" alt="" />
          <div class="mappingCenterFiveth1-1-1">
            <h2>漏洞防护</h2>
            <p>
              某单位由于架构变动接手-堆业务系统,短期内无法快速对系统进行渗透融试及安全整改,但由于即将到来的护网行动和上级单位的安全审查导致安全压力巨大。需要短期快速对-堆业务系统进行安全加固。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="codeAuditThird">
      <h1 id="proYs">产品优势</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">保护后端</p>
              <span class="codeAuditThird1-2-2">
                真实后端服务器被隐藏,类似于CDN,攻击者无法通过正常方式访问到真实后端服务器
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">高兼容</p>
              <span class="codeAuditThird1-2-2">
                采用了浏览器最新标固的Web特性,高兼容,不受操作系统或浏览器版本影响
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">用户无感</p>
              <span class="codeAuditThird1-2-2">
                浏览器中地址栏与请求路径保持正常,对于用户来说是透明的。用户几乎感
                知不到安全措施,且不会导致用户无法访问,不会影响用户的使用习惯。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">低延时</p>
              <span class="codeAuditThird1-2-2">
                前端代理操作运行在浏览器后台,不会阻塞主线程,因此即使进行复杂的
                加密操作也不会显著影响页面加载速度或用户体验。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToElement(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mappingCenterAll {
  width: 100%;
  .box {
    margin: 0 auto;
  }
  .tabs {
    display: flex;
    justify-content: center;

    height: 95px;
    border-bottom: 1px solid #dadada;
  }
  .tabs li {
    float: left;
    // margin-right: 87px;
    list-style: none;
  }

  .tabs .tab-link {
    display: block;
    width: 270px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    font-size: 20px;
    color: #000;
    text-decoration: none;
  }
  .tabs .tab-link:hover {
    cursor: pointer;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #3d4ed0;
    transition: 0.3s;
  }
  .mappingCenterFirst {
    background-image: url(../../../../assets/img/proctCenter/6.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 200px;
    .mappingCenterFirst1 {
      width: 100%;
      margin: 0 auto;
    }
    h1 {
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 2%;
      color: #fff;
    }
    p {
      width: 420px;
      font-size: 20px;
      margin-top: 10px;
      color: #fff;
    }
  }
  .mappingCenterSecond {
    margin-top: 40px;
    background: #f1f5ff;
    padding: 30px;
    h1 {
      text-align: center;
    }
    .mappingCenterSecond1 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .mappingCenterSecond1Left {
        margin-right: 30px;
        img {
          width: 500px;
        }
      }
      .mappingCenterSecond1Right {
        width: 32%;
        p {
          text-indent: 2rem;
          font-size: 16px;
          font-family: "微软雅黑";
          color: #51565d;
          margin-top: 10px;
          line-height: 1.8;
        }
      }
    }
  }
  .mappingCenterThird {
    margin-top: 40px;
    background: #f1f5ff;
    padding: 30px;
    h1 {
      text-align: center;
    }
    p {
      text-indent: 2rem;
      font-size: 16px;
      font-family: "微软雅黑";
      color: #51565d;
      margin-top: 10px;
      line-height: 1.8;
      text-align: center;
    }
    .mappingCenterThird1 {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      img {
        width: 700px;
        margin-left: 20px;
        height: 302px;
      }
    }
  }
  .mappingCenterFourth {
    margin-top: 40px;
    background: #f1f5ff;
    padding: 30px;
    h1 {
      text-align: center;
    }
    .mappingCenterFourth1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; /* 居中排列 */
      margin-top: 40px;
    }
    .mappingCenterFourth1-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%; /* Set each item to occupy 25% of the container width */
      padding: 10px;
      box-sizing: border-box;
      margin: 5px;
    }
    .mappingCenterFourth1-1 img {
      width: 15%; /* 图片占据容器的一半宽度 */
      height: auto;
    }
    .mappingCenterFourth1-1-1 {
      width: 60%; /* 文本占据另一半宽度 */
      padding-left: 10px; /* 为文本添加一些左侧间距 */
    }
    /* 响应式布局，当屏幕宽度较小时，每行显示较少的项目 */
    @media (max-width: 1200px) {
      .mappingCenterFourth1-1 {
        width: 50%; /* 调整为每行三个项目 */
      }
    }
    @media (max-width: 768px) {
      .mappingCenterFourth1-1 {
        width: 100%; /* 调整为每行两个项目 */
      }
    }
    @media (max-width: 480px) {
      .mappingCenterFourth1-1 {
        width: 90%; /* 调整为每行一个项目 */
      }
    }
  }
  .codeAuditThird {
    width: 100%;
    margin-top: 40px;
    background: #f1f5ff;
    padding: 50px 0 20px 0;
    h1 {
      text-align: center;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40px;
      li {
        width: 35.5%;
        display: flex;
        padding: 20px;
        background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
        border-radius: 12px;
        margin-top: 20px;
        margin-left: 20px;
        .codeAuditThird1-1 {
          img {
            width: 48px;
            height: 48px;
            margin-right: 20px;
          }
        }
        .codeAuditThird1-2 {
          .codeAuditThird1-2-1 {
            font-size: 24px;
            color: #1f2329;
          }
          .codeAuditThird1-2-2 {
            font-size: 16px;
            color: #51565d;
            margin-top: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
  .mappingCenterFiveth {
    margin-top: 40px;
    padding: 30px;
    h1 {
      text-align: center;
    }
    .mappingCenterFiveth1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; /* 居中排列 */
      margin-top: 40px;
    }
    .mappingCenterFiveth1-1 {
      width: 25%; /* 设置容器宽度 */
      margin-left: 20px; /* 添加间距 */
      box-sizing: border-box; /* 确保padding和border不影响宽度计算 */
      display: flex; /* 将容器设置为弹性盒子 */
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
    .mappingCenterFiveth1-1 img {
      width: 25%; /* 图片宽度占满容器 */
      height: auto; /* 保持图片的宽高比 */
      margin-bottom: 10px; /* 图片与文字之间的间距 */
    }
    .mappingCenterFiveth1-1-1 {
      text-align: center; /* 文本居中对齐 */
      p {
        width: 400px;
      }
    }
    /* 响应式布局，当屏幕宽度较小时，每行显示较少的项目 */
    @media (max-width: 1200px) {
      .mappingCenterFiveth1-1 {
        width: 40%; /* 调整为每行两个项目 */
      }
    }
    @media (max-width: 768px) {
      .mappingCenterFiveth1-1 {
        width: 80%; /* 调整为每行一个项目 */
      }
    }
  }
}
</style>
